import React from "react"
import Link from "gatsby-link"

export default () => (
    <div className="content">
      <h1>This content is intended to be pulled by another website!</h1>
      <p>We're using the JavaScript fetch function to bring you this content!</p>
      <span className="content-image">
        <img src="../sea-boat.jpg" alt="sea boat!" />
        <object data="https://optimistic-hoover-2b6ef4.netlify.app/unicom-capital-logo-we.svg" type="image/svg+xml">
          <img src="https://images.unsplash.com/photo-1496449903678-68ddcb189a24?ixid=MXwxMjA3fDB8MHxzZWFyY2h8Mnx8cmFuZG9tfGVufDB8fDB8&ixlib=rb-1.2.1&w=1000&q=80" alt="random" />
        </object>      
      </span>

      <Link to="/">Home</Link>
    </div>
)